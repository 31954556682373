<template>
  <b10-base>
    <v-alert
      type="info"
      :value="true"
    >
      La plantilla tiene {{ Object.keys(form).length }} {{ 'grupo'|pluralize(Object.keys(form).length, 's') }}.<br>
      Debes indicar en que subsistemas (nuevos o existentes) quieres añadir el material de cada grupo.
    </v-alert>
    <v-card
      v-for="(field, key) in form"
      :key="key"
      class="mb-3"
      tile
    >
      <v-card-title>Grupo "{{ field.grupo.plantilla_grupo_articulo.descripcion }}"</v-card-title>
      <v-card-text>
        <ValidationProvider
          v-slot="{ errors }"
          name="subsistema"
          :rules="field.formRules.idsubsis_idtsubsis"
        >
          <b10-autocomplete
            v-model="field.fields.idsubsis_idtsubsis"
            :items="subsisTsubsis"
            item-value="index"
            item-text="descripcion"
            label="Subsistema"
            clearable
            :error-messages="errors"
            @change="subsisTsubsisSelected(field)"
          >
            <template
              #item="{ item, attrs, on }"
            >
              <v-list-item
                v-bind="attrs"
                :disabled="item.value.tipo === 'divider'"
                v-on="on"
              >
                <v-list-item-content
                  v-if="item.value.tipo === 'divider'"
                  :class="item.value.color"
                  class="white--text pa-1 rounded"
                >
                  {{ item.descripcion }}
                </v-list-item-content>
                <v-list-item-content
                  v-else
                >
                  {{ item.descripcion }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </b10-autocomplete>
        </ValidationProvider>
        <template
          v-if="subsisTsubsis[field.fields.idsubsis_idtsubsis] && subsisTsubsis[field.fields.idsubsis_idtsubsis].value.tipo === 'tsubsis'"
        >
          <v-alert
            color="warning"
            dark
            dense
            :value="true"
          >
            Se creará un nuevo subsistema de tipo "{{ subsisTsubsis[field.fields.idsubsis_idtsubsis].descripcion }}"
          </v-alert>
          <ValidationProvider
            v-slot="{ errors }"
            name="subsis_codigo"
            :rules="field.formRules.subsis_codigo"
          >
            <v-text-field
              v-model="field.fields.subsis_codigo"
              clearable
              flat
              label="Código del subsistema"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="subsis_descripcion"
            :rules="field.formRules.subsis_descripcion"
          >
            <v-text-field
              v-model="field.fields.subsis_descripcion"
              clearable
              flat
              label="Descripción del subsistema"
              :error-messages="errors"
            />
          </ValidationProvider>
        </template>
      </v-card-text>
    </v-card>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './PlantillaArticuloAplicarFormMaterialSistemaData'
import  { extend } from 'vee-validate'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idsistema: {
      type: [Number, String],
      required: true,
    },
  },
  data () {
    return {
      form: {},
      formRules: {},
      subsisTsubsis: [],
      inCodigosExistentes: [],
    }
  },
  async created () {
    extend('codigoUnico', {
      message: 'Código de subsistema duplicado.',
      validate: async (value) => {
        return (!this.inCodigosExistentes.includes(value))
      }
    })
    this.inCodigosExistentes = await Data.selectCodigosSubsis(this, this.idsistema)
    this.subsisTsubsis = await this.$offline.subsis.selectSubsisTsubsis(this.idsistema)
    // agregar index como propiedad
    for (const index in this.subsisTsubsis) {
      this.$set(this.subsisTsubsis[index], 'index', parseInt(index))
    }
    // por cada grupo de la plantilla
    const gruposPlantilla = await Data.selectGruposPlantilla(this, this.id)
    for (const grupo of gruposPlantilla) {
      let formGrupo = {
        idplantilla_grupo_articulo: grupo.plantilla_grupo_articulo.idplantilla_grupo_articulo,
        grupo,
        fields: {
          idsubsis_idtsubsis: null,
          subsis_codigo: null,
          subsis_descripcion: null,
        },
        formRules: {
          idsubsis_idtsubsis: { required: true },
          subsis_codigo: { required: true, codigoUnico: true },
          subsis_descripcion: { required: true },
        },
      }
      // subsis/tsubsis por defecto
      const subsisIndex = _.findIndex(this.subsisTsubsis, { value: { tipo: 'subsis', idtsubsis: grupo.plantilla_grupo_articulo.idtsubsis } })
      if (subsisIndex > -1) {
        formGrupo.fields.idsubsis_idtsubsis = subsisIndex
      } else {
        const tsubsisIndex = _.findIndex(this.subsisTsubsis, { value: { tipo: 'tsubsis', idtsubsis: grupo.plantilla_grupo_articulo.idtsubsis } })
        if (tsubsisIndex > -1) {
          formGrupo.fields.idsubsis_idtsubsis = tsubsisIndex
        }
      }
      this.$set(
        this.form,
        `plantilla_grupo_articulo_${grupo.plantilla_grupo_articulo.idplantilla_grupo_articulo}`,
        formGrupo
      )
      // forzar evento
      this.subsisTsubsisSelected(formGrupo)
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    subsisTsubsisSelected (field) {
      const fieldIndex = `plantilla_grupo_articulo_${field.grupo.plantilla_grupo_articulo.idplantilla_grupo_articulo}`
      if (fieldIndex in this.form) {
        if (this.form[fieldIndex].fields.idsubsis_idtsubsis > 0) {
          const subsisTsubsisValue = this.subsisTsubsis[this.form[fieldIndex].fields.idsubsis_idtsubsis].value
          if (subsisTsubsisValue.codigoPorDefecto && subsisTsubsisValue.descripcionPorDefecto) {
            this.$set(this.form[fieldIndex].fields, 'subsis_codigo', subsisTsubsisValue.codigoPorDefecto)
            this.$set(this.form[fieldIndex].fields, 'subsis_descripcion', subsisTsubsisValue.descripcionPorDefecto)
          } else {
            this.$set(this.form[fieldIndex].fields, 'subsis_codigo', null)
            this.$set(this.form[fieldIndex].fields, 'subsis_descripcion', null)
          }
        } else {
          this.$set(this.form[fieldIndex].fields, 'subsis_codigo', null)
          this.$set(this.form[fieldIndex].fields, 'subsis_descripcion', null)
        }
      }
    },
  }
}
</script>
