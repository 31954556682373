var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('v-alert',{attrs:{"type":"info","value":true}},[_vm._v(" La plantilla tiene "+_vm._s(Object.keys(_vm.form).length)+" "+_vm._s(_vm._f("pluralize")('grupo',Object.keys(_vm.form).length, 's'))+"."),_c('br'),_vm._v(" Debes indicar en que subsistemas (nuevos o existentes) quieres añadir el material de cada grupo. ")]),_vm._l((_vm.form),function(field,key){return _c('v-card',{key:key,staticClass:"mb-3",attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Grupo \""+_vm._s(field.grupo.plantilla_grupo_articulo.descripcion)+"\"")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"subsistema","rules":field.formRules.idsubsis_idtsubsis},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.subsisTsubsis,"item-value":"index","item-text":"descripcion","label":"Subsistema","clearable":"","error-messages":errors},on:{"change":function($event){return _vm.subsisTsubsisSelected(field)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":item.value.tipo === 'divider'}},'v-list-item',attrs,false),on),[(item.value.tipo === 'divider')?_c('v-list-item-content',{staticClass:"white--text pa-1 rounded",class:item.value.color},[_vm._v(" "+_vm._s(item.descripcion)+" ")]):_c('v-list-item-content',[_vm._v(" "+_vm._s(item.descripcion)+" ")])],1)]}}],null,true),model:{value:(field.fields.idsubsis_idtsubsis),callback:function ($$v) {_vm.$set(field.fields, "idsubsis_idtsubsis", $$v)},expression:"field.fields.idsubsis_idtsubsis"}})]}}],null,true)}),(_vm.subsisTsubsis[field.fields.idsubsis_idtsubsis] && _vm.subsisTsubsis[field.fields.idsubsis_idtsubsis].value.tipo === 'tsubsis')?[_c('v-alert',{attrs:{"color":"warning","dark":"","dense":"","value":true}},[_vm._v(" Se creará un nuevo subsistema de tipo \""+_vm._s(_vm.subsisTsubsis[field.fields.idsubsis_idtsubsis].descripcion)+"\" ")]),_c('ValidationProvider',{attrs:{"name":"subsis_codigo","rules":field.formRules.subsis_codigo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Código del subsistema","error-messages":errors},model:{value:(field.fields.subsis_codigo),callback:function ($$v) {_vm.$set(field.fields, "subsis_codigo", $$v)},expression:"field.fields.subsis_codigo"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"subsis_descripcion","rules":field.formRules.subsis_descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción del subsistema","error-messages":errors},model:{value:(field.fields.subsis_descripcion),callback:function ($$v) {_vm.$set(field.fields, "subsis_descripcion", $$v)},expression:"field.fields.subsis_descripcion"}})]}}],null,true)})]:_vm._e()],2)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }